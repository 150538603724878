// 1000 - 1014 -> 131000 - 131014
export const CONF_SUCCESS = 0; // used
export const CONF_FAIL_MEETING_OVER = 6; // used
export const CONF_FAIL_CONF_USER_FULL = 9; // used
export const CONF_FAIL_CONFLOCKED = 12; // used
export const CONF_FAIL_JOIN_WEBINAR_RESTRICTION = 79; //used
export const CONF_FAIL_ACCOUNT_ID_RESTRICTED = 80;
export const BLOCK_REGION = 1142; // used
export const ERROR_WEBINR_DENIED_EMAIL = 3033; // used
export const CONF_FAIL_MEETING_HAS_CLOSED = 103003; // used: join the conf but closed
export const CONF_FAIL_HAS_BEEN_REMOVED = 103039; // used
export const CONF_RESULT_MMR_IB_REJECT = 103043; // used: reject for information barriers
export const CONF_FAIL_RESULT_MMR_CONF_PARTICIPANT_EXISTS = 103044; // used: disallow join from multiple devices
export const CONF_FAIL_NETWORK_ERR = 1;
export const CONF_FAIL_RECONNECT_ERR = 2;
export const CONF_FAIL_MMR_ERR = 3;
export const CONF_FAIL_PASSWORD_ERR = 4;
export const CONF_FAIL_SESSION_ERR = 5;
export const CONF_FAIL_MEETING_NOT_START = 7;
export const CONF_FAIL_MEETING_NOT_EXIST = 8;
export const CONF_FAIL_CLIENT_INCOMPATIBLE = 10; // RESULT_ZC_INCOMPATIBLE
export const CONF_FAIL_NO_MMR = 11;
export const CONF_FAIL_MEETING_RESTRICTED = 13;
export const CONF_FAIL_MEETING_RESTRICTED_JBH = 14;
export const CONF_FAIL_CANNOT_EMIT_WEBREQUEST = 15;
export const CONF_FAIL_CANNOT_START_TOKENEXPIRE = 16;
export const SESSION_VIDEO_ERR = 17;
export const SESSION_AUDIO_AUTOSTARTERR = 18;
export const CONF_FAIL_REGISTERWEBINAR_FULL = 19;
export const CONF_FAIL_REGISTERWEBINAR_HOSTREGISTER = 20;
export const CONF_FAIL_REGISTERWEBINAR_PANELISTREGISTER = 21;
export const CONF_FAIL_REGISTERWEBINAR_DENIED_EMAIL = 22;
export const CONF_FAIL_REGISTERWEBINAR_ENFORCE_LOGIN = 23;
export const CONF_FAIL_ZC_CERTIFICATE_CHANGED = 24;
export const CONF_FAIL_WRITE_CONFIG_FILE = 50; // Failed to write configure file
