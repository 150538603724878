import { DEVICE_CHANGE_EVENT } from '../../constants/AVNotifyAPPTypes';
import deviceManager from '../../device-manager';
import eb from '../../global/event-bus';

function initDeviceManagerJob() {
  // getDeviceListAsync from MediaSDK
  if (
    typeof JsMediaSDK_Instance !== 'undefined' &&
    JsMediaSDK_Instance.util?.getDeviceListAsync
  ) {
    deviceManager.addGetDeviceListMethodFromMediaSDK(
      JsMediaSDK_Instance.util.getDeviceListAsync,
    );
  }
  deviceManager.init();
  eb.on('media-sdk-event', ([type, data]) => {
    if (type === DEVICE_CHANGE_EVENT) {
      deviceManager.emit('HANDLE_DEVICE_CHANGED', data);
    }
  });
  return;
}

export const taskInitDeviceManager = [initDeviceManagerJob];
