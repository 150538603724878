import { isTransferMeeting } from './util';

export const PWAMeetingEvent = {
  // meeting status event send by web-client
  NONE: 'NONE',
  JOINING: 'JOINING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  LEAVE: 'LEAVE', // the child window still exist
  CLOSED: 'CLOSED',
  END: 'END',
  // other event send by web-client
  INVITE_START: 'INVITE_START', // start to get contacts and user details
  INVITE_STOP: 'INVITE_STOP', // like unsubscribe
  ZOOM_PHONE_CALL: 'ZOOM_PHONE_CALL', // call
  ZOOM_PHONE_SUCCESS: 'ZOOM_PHONE_SUCCESS', // invite success
  ZOOM_PHONE_CHANGE: 'ZOOM_PHONE_CHANGE', // change callerid
  ZOOM_PHONE_ERROR: 'ZOOM_PHONE_ERROR', // error
  ZOOM_PHONE_CALL_HANGUP: 'ZOOM_PHONE_CALL_HANGUP', // hangup
  INVITE_FETCH_USER_DETAILS: 'INVITE_FETCH_USER_DETAILS', // fetch specific range users' details
  INVITE_INVITE_TO_MEETING: 'INVITE_INVITE_TO_MEETING',
  ASK_TO_INVITE_TO_MEETING: 'ASK_TO_INVITE_TO_MEETING',
  /**
   * @param {number} num;
   */
  WAITING_ROOM_NEW: 'WAITING_ROOM_NEW',
  LIVE_TRANSCRIPTION_ENABLED: 'LIVE_TRANSCRIPTION_ENABLED',
  MUTED_BY_HOST: 'MUTED_BY_HOST',
  UNMUTE_REQUEST: 'UNMUTE_REQUEST',
  VIDEO_REQUEST: 'VIDEO_REQUEST',
  /**
   * @param {string} title;
   */
  RECORDING: 'RECORDING',
  /**
   * @param {string} hostName;
   * @param {string} roomName;
   */
  INVITED_TO_BO: 'INVITED_TO_BO',
  BO_TIME_UP: 'BO_TIME_UP',
  /**
   * @param {string} name;
   */
  LIVE_TRANSCRIPTION_REQUEST: 'LIVE_TRANSCRIPTION_REQUEST',
  POLL_INBOX: 'POLL_INBOX',
  MEETING_MINIMIZE: 'MEETING_MINIMIZE',
  MEETING_MAXIMIZE: 'MEETING_MAXIMIZE',
  DRAG_START: 'DRAG_START',
  DRAG_MOVE: 'DRAG_MOVE',
  DRAG_END: 'DRAG_END',
  END_BY_HOST: 'END_BY_HOST',
  AUDIO_CONNECTED_CHANGE: 'AUDIO_CONNECTED_CHANGE',
  BAN_VIDEO: 'BAN_VIDEO',
  PROMOTE_TO_PANELIST: 'PROMOTE_TO_PANELIST',
  /**
   * @param {string} inviterName
   */
  RETURN_MAIN_SESSION: 'RETURN_MAIN_SESSION',
  REMOVED_BY_HOST: 'REMOVED_BY_HOST',
  RENAMED_BY_HOST: 'RENAMED_BY_HOST',
  BAN_UNMUTE_AUDIO: 'BAN_UNMUTE_AUDIO',
  POLL_RESULT_SHARED: 'POLL_RESULT_SHARED',
  BO_WILL_CLOSE: 'BO_WILL_CLOSE',
  /**
   * @param {string} roomName
   * @param {string} attendeeName
   */
  HELP_REQUEST: 'HELP_REQUEST',
  /**
   * @param {string} pwa_link
   */
  NEED_REGISTER: 'NEED_REGISTER',

  // event send by pwa
  PWA_EXIST: 'PWA_EXIST',
  PWA_STATUS: 'PWA_STATUS',
  INVITE_CONTACTS: 'INVITE_CONTACTS', // send after INVITE_START
  INVITE_USER_DETAILS_UPDATE: 'INVITE_USER_DETAILS_UPDATE', // send when any user details change
  INVITE_USER_DECLINED: 'INVITE_USER_DECLINED',
  PWA_LEAVE_MEETING: 'PWA_LEAVE_MEETING',
  BACK_TO_MEETING: 'BACK_TO_MEETING',
  LEAVE_AUDIO: 'LEAVE_AUDIO',
  END_MEETING_REQUEST: 'END_MEETING_REQUEST',
  MEETING_MINIMIZE_REQUEST: 'MEETING_MINIMIZE_REQUEST',
  START_PRESENTING: 'START_PRESENTING',
  STOP_PRESENTING: 'STOP_PRESENTING',
  CHECK_IS_IN_CHANNEL: 'CHECK_IS_IN_CHANNEL',
  JUMP_CHAT_CHANNEL: 'JUMP_CHAT_CHANNEL',
  SEND_CHANNEL_ID: 'SEND_CHANNEL_ID',
  LCP_RESPONSE: 'LCP_RESPONSE',
  LCP_REQUEST: 'LCP_REQUEST',
  FECC_GROUP_REQUEST: 'FECC_GROUP_REQUEST',
  FECC_GROUP_RESPONSE: 'FECC_GROUP_RESPONSE',
  FECC_GROUP_ADD: 'FECC_GROUP_ADD',
  FECC_GROUP_ADD_RESPONSE: 'FECC_GROUP_ADD_RESPONSE',
  FECC_GROUP_REMOVE: 'FECC_GROUP_REMOVE',
  FECC_GROUP_REMOVE_RESPONSE: 'FECC_GROUP_REMOVE_RESPONSE',
  FECC_GROUP_PWA_ADD: 'FECC_GROUP_PWA_ADD',
  FECC_GROUP_PWA_REMOVE: 'FECC_GROUP_PWA_REMOVE',
  MEETING_TOPIC_UPDATE: 'MEETING_TOPIC_UPDATE',
  ATTENDEE_WILL_TRANSFER_TO_WR: 'ATTENDEE_WILL_TRANSFER_TO_WR',
};

export const SWError = {
  NO_PWA: 'NO_PWA',
};

/**
 * it can set the exist state only first time for PWA and return the current exist state
 * @type {function(*=): boolean}
 */
export const PWAExist = () => {
  if (window.parent && window.self !== window.parent) {
    try {
      return window.parent.name === 'PWA';
    } catch (e) {
      // cross origin will get here, we consider it as false
    }
  }
  return false;
};

export const sendMsgToPWA = (event, data = {}) => {
  /* eslint-disable-next-line @babel/new-cap */
  if (!PWAExist()) {
    return Promise.resolve(SWError.NO_PWA);
  }
  const payload = {
    message: { event, data },
    type: 'PWA_IPC',
  };

  window.parent.postMessage(payload, parent?.location.origin || '*');
  return Promise.resolve(true);
};

export function sendIakToPWA(body) {
  const { inviteeIakList = [] } = body;
  return () => {
    sendMsgToPWA(
      PWAMeetingEvent.INVITE_INVITE_TO_MEETING,
      inviteeIakList.map((item) => ({
        jid: item.inviteeID,
        credential: item.cred,
        iak: item.iak,
      })),
    );
  };
}

export function sendJoinSuccessMsgToPWA() {
  sendMsgToPWA(PWAMeetingEvent.SUCCESS);
  /**
   * when pwa transfers meeting from other client and loads weblcient inside iframe,
   * it'll add transfer=1 param.
   * after we join meeting successfully, we need to clear this param.
   * or webclient will ask pwa for transfer data once it refreshes itself.
   * But transfer data can only be used ONCE. Rwg fails to check transfer data if data was used before
   */

  if (isTransferMeeting) {
    const url = new URL(window.location.href);
    if (url.searchParams.has('transfer')) {
      url.searchParams.delete('transfer');
      window.history.replaceState(
        window.history.state,
        window.document.title,
        url,
      );
    }
  }
}
