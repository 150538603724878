import { createContext } from 'react';

export let globalVariable = {
  storeContext: (() => {
    let localContext = null;
    return {
      setContext(context) {
        localContext = createContext(context);
      },
      getContext() {
        if (!localContext) throw Error('Context is not created');
        return localContext;
      },
    };
  })(),
  avSocket: null,
  removeStorageEventListener: null,
  downloadTask: null,
  whiteboardSdk: null,
  performanceHandler: null,
  shouldReportPerformance: false,
  wcCTX: () => {
    return {};
  },
};
