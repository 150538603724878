import meetingConfig from 'meetingConfig';
import { makeLogger } from './logger';
import { IResourceManager } from '@zoom/resource-manager';
import {
  getAVMeetingParams,
  isSupportHardwareAcceleration,
  isSupportVB,
  getVideoRwcIpAddress,
  getAudioRwcIpAddress,
  getWebMediaBlockConfig,
} from './service';
import { AVSocketOutboundMsg, infoLog, warningLog } from './web-client-logger';
import * as AVNotifyMediaSDKTypes from '../constants/AVNotifyMediaSDKTypes';
import { sdkIvTypeKeyEnum } from './crypto';
import { LOCALSTORAGE_KEYS, MEMORYSTORAGE_KEYS } from './constant';
import {
  isSupportAudioWorklet,
  isMobileDevice,
  isChrome,
  getMaxConcurrenyVideoThread,
  isMSFTMode,
  isExternalControlledMode,
  isTeslaMode,
  isSupportAV,
  isChromeOS,
  isGoogleMeetMode,
} from './util';
import {
  is360penablehw,
  replaceCanvasWhenWebglContextLost,
  isEnableDecoderWorkletFlag,
} from './op-feature-option';
import { PERFORMANCE_MARK, performanceMark } from '../performance';
import { isWebinar } from './service/meeting-types';
import { isAllowOnlyCanTalk, isViewOnly } from './service/user-types';
import {
  createFullResourceUrl,
  getMediasdkBaseUrl,
  getVbResourceUrl,
} from '../tasks/global/task-utils/common-utils';
import { globalVariable } from './global-variable';
import eb from './event-bus';
import {
  AVS_TAGS,
  avsLogReport,
} from './logger/log-service/avs-laplace-telemetry';
import { AVNotifyMediaSDKTypeNames } from '../constants/media-sdk-type-string';
import { safeJsonStringifyWithNoNest } from './utils/safe-json';
import { isExcludedMediaSendType } from './logger/is-laplace-enabled';
import { easyStore, storeType } from './easy-store';
import { isAudioBridge } from './service/audio-bridge-service';

(function buildSIMDFlag() {
  let isValid = false;
  if (meetingConfig.isSIMDEnabled && isChrome()) {
    try {
      const cv = Number(
        navigator.appVersion.match(/.*Chrome\/([0-9.]+)/)[1].split('.')[0],
      );
      isValid = cv >= 84;
    } catch (e) {
      /* eslint-disable-next-line no-console */
      console.error('ue check failed for SIMD');
      isValid = false;
    }
  }

  const localSMIDFlag = localStorage.getItem(
    LOCALSTORAGE_KEYS.webClient_SIMD_Flag,
  );
  isValid = isValid || localSMIDFlag;

  if (!isValid) {
    return;
  }
  const wclSIMDConfig = {
    '*.zoomdev.us.20200828':
      'AqDmKA5ya62UiUuVktlcZPI7wjqF1GSZZ5TewRA6rJDEUaAqLn/rcay61WFvxPE/qB1DQkWO54PPP1spsqYCZwcAAABmeyJvcmlnaW4iOiJodHRwczovL3pvb21kZXYudXM6NDQzIiwiZmVhdHVyZSI6IldlYkFzc2VtYmx5U2ltZCIsImV4cGlyeSI6MTYwMjIwNTMyNSwiaXNTdWJkb21haW4iOnRydWV9',
    '*.zoom.us.20201009':
      'AuDEnVf6mo22q0A7IMSEBRdu4ziJ/jrqP+yRD7CMfsBwhcu58L7SQtYZOqM4lGzIB+Fz+aiqshaa+OUSNQn06wgAAABjeyJvcmlnaW4iOiJodHRwczovL3pvb20udXM6NDQzIiwiZmVhdHVyZSI6IldlYkFzc2VtYmx5U2ltZCIsImV4cGlyeSI6MTYwNTg0MzA4OSwiaXNTdWJkb21haW4iOnRydWV9',
    '*.zipow.com.20201009':
      'AlhmpxZFa9hMmUw7mkHj/xcvhffnWEDCnrVjASNFnRuu6cKeMi9hMJJhoBY45X6/uGrTHVQBi3e3xiNWSN9S3wYAAABleyJvcmlnaW4iOiJodHRwczovL3ppcG93LmNvbTo0NDMiLCJmZWF0dXJlIjoiV2ViQXNzZW1ibHlTaW1kIiwiZXhwaXJ5IjoxNjA1ODQzMTY3LCJpc1N1YmRvbWFpbiI6dHJ1ZX0=',
    'zoom.com.cn.20201009':
      'AqTdtZezhFpgj7HK10XVTPalbxxfWU0KwAQCVST7iY0hIpgs67f/uUPY7bTR+Sx4KSnvLzOylcOR49GV2lxwcAoAAABneyJvcmlnaW4iOiJodHRwczovL3pvb20uY29tLmNuOjQ0MyIsImZlYXR1cmUiOiJXZWJBc3NlbWJseVNpbWQiLCJleHBpcnkiOjE2MDU4NDMxMzcsImlzU3ViZG9tYWluIjp0cnVlfQ==',
    'www.zoomgov.com.20201009':
      'AiJ+fJKK/6Iroww3cfFbGq9pS543BYv8ZF+dQh2DCG/F2fQj1Cl/nDoMlzD2HYhXHU/nOrn4GVzeF7Iu7mZktgAAAABreyJvcmlnaW4iOiJodHRwczovL3d3dy56b29tZ292LmNvbTo0NDMiLCJmZWF0dXJlIjoiV2ViQXNzZW1ibHlTaW1kIiwiZXhwaXJ5IjoxNjA1ODQzMjA2LCJpc1N1YmRvbWFpbiI6dHJ1ZX0=',
  };
  const simdArray = meetingConfig.simdLicense
    ? meetingConfig.simdLicense.split(',')
    : Object.values(wclSIMDConfig);

  if (simdArray) {
    simdArray.forEach((v) => {
      const meta = document.createElement('meta');
      meta.httpEquiv = 'origin-trial';
      meta.content = v;
      document.getElementsByTagName('head')[0].appendChild(meta);
    });
  }
})();

export class AVSocketService {
  constructor(initStatus, socketCallBack, isPreviewMode) {
    this.initStatus = initStatus;
    this.socketCallBack = socketCallBack;
    this._socketCallback = this._socketCallback.bind(this);
    this.canvasInfo = {};
    this.joinVoipTimes = 0;

    this.mediaWorkPath = { basePath: getMediasdkBaseUrl() };
    this.isDestroyed = false;
    let sdkInitOpt = {
      ivObj: {
        [sdkIvTypeKeyEnum.VIDEO_ENCODE]: easyStore.getIv(
          sdkIvTypeKeyEnum.VIDEO_ENCODE,
        ),
        [sdkIvTypeKeyEnum.AUDIO_ENCODE]: easyStore.getIv(
          sdkIvTypeKeyEnum.AUDIO_ENCODE,
        ),
        [sdkIvTypeKeyEnum.SHARING_ENCODE]: easyStore.getIv(
          sdkIvTypeKeyEnum.SHARING_ENCODE,
        ),
      },
      globalTracingLogger: makeLogger([
        isPreviewMode ? 'MEDIA SDK PREVIEW' : 'MEDIA SDK',
      ]),
    };

    const options = {
      isTeslaMode: isTeslaMode(),
      isGoogleMeetMode: isGoogleMeetMode(),
      enableReplaceCanvasWhenContextLost:
        isMSFTMode() && replaceCanvasWhenWebglContextLost,
      enableDecoderInWorklet: isEnableDecoderWorkletFlag(),
      enableAudioBridge: isAudioBridge(),
      onDesktop: true,
    };

    if (isSupportVB()) {
      const vbUrl = getVbResourceUrl();
      performanceMark(PERFORMANCE_MARK.inMeeting_initVB);
      options.resourceManager = IResourceManager;
      options.file = [
        {
          path: createFullResourceUrl(vbUrl, 'dualModel.bin'),
          type: 'bin',
        },
        { path: createFullResourceUrl(vbUrl, 'tf.min.js'), type: 'js' },
      ];
    }

    this.socketInstance = new JsMediaSDK_Instance(sdkInitOpt);
    //[3.5.0][MediaSDK_Operation] js media preload
    avsLogReport('js media preload', AVS_TAGS.MediaSDK_Operation); // only one tag, it can be a string.
    /* eslint-disable-next-line @babel/new-cap */
    this.socketInstance.JsMediaSDK_PreLoad(
      this.mediaWorkPath,
      this._socketCallback,
      options,
    );
  }

  updateSocketCallback(socketCallBack) {
    this.socketCallBack = socketCallBack;
  }

  _socketCallback(...args) {
    if (this.socketCallBack) {
      this.socketCallBack(...args);
    }
    eb.emit('media-sdk-event', args);
  }

  subscribeToQos(workers, pollingInterval) {
    // Start every second beating from audio/video workers depending on
    // what is requested
    workers.forEach((worker) => {
      this.socketInstance.updateQosSubscription(true, worker, pollingInterval);
    });
  }

  unsubscribeFromQos(workers) {
    workers.forEach((worker) => {
      this.socketInstance.updateQosSubscription(false, worker);
    });
  }

  getCurrentTransferType(type) {
    return this.socketInstance.getCurrentTransferType(type);
  }

  disableWebRTC() {
    //[3.5.0][MediaSDK_Operation] js media close all rtc
    avsLogReport('js media close all rtc', AVS_TAGS.MediaSDK_Operation);
    return this.socketInstance.closeAllRTC();
  }

  isEnableAutoJoinAudio() {
    return this.socketInstance.isEnableAutoJoinAudio?.();
  }

  initParams = (meetingState, socketStatus, videoState, policy, useWBVideo) => {
    this.meetingState = meetingState;
    this.socketStatus = socketStatus;
    this.videoState = videoState;
    this.isWebinar = isWebinar();
    this.isViewOnly = isViewOnly(meetingState.currentUser.userRole);
    this.isAllowToTalk = isAllowOnlyCanTalk(meetingState.currentUser.userRole);
    const { meetingJoinStatus, meetingNumber, conID, svcUrl, mediasdkConfig } =
      this.meetingState;
    if (isSupportAV()) {
      infoLog('>>>>>>>>INIT JSMEDIASDK<<<<<<<<');
      this.avMeetingParams = getAVMeetingParams({
        meetingStatus: meetingJoinStatus,
        meetingNumber,
        conferenceID: conID,
        svcUrl,
      });
    } else {
      infoLog('>>>>>>>>INIT JSMEDIASDK WHEN NOT SUPPORT AV <<<<<<<<');
      this.avMeetingParams = getAVMeetingParams({
        meetingStatus: meetingJoinStatus,
        meetingNumber,
        conferenceID: conID,
        receiveSharingByJPG: true,
        svcUrl,
      });
    }
    const bandwidth = {};
    if (!_.isNil(policy)) {
      const bandwidthLimitUp = parseInt(policy.BandwidthLimitUp, 10);
      const bandwidthLimitDown = parseInt(policy.BandwidthLimitDown, 10);
      if (!Number.isNaN(bandwidthLimitUp)) {
        bandwidth.uplimit = bandwidthLimitUp;
      }
      if (!Number.isNaN(bandwidthLimitDown)) {
        bandwidth.downlimit = bandwidthLimitDown;
      }
    }

    const { e2eEncrypt, enableWebtransport, webtransportPort } =
      this.meetingState;

    const rendererType =
      easyStore.easyGet(LOCALSTORAGE_KEYS.video_render_method_type) || 0;
    const propsBeforeInit = {
      callback: this._socketCallback,
      e2eEncrypt,
      mediasdkConfig,
      isEnableListenInitEvent: true,
      bandwidth,
      featureOptions: meetingConfig.webclientFeatureOptions,
      rendererType,
      webMediaBlockConfig: getWebMediaBlockConfig(),
      useWebRTC: useWBVideo,
      isSessionBranding: !!meetingConfig.sessionBranding?.mvbUrl,
      mediaFeatureOptions: meetingConfig.mediaFeatureOptions,
    };
    //[3.5.0][MediaSDK_Operation] js media set props before init
    avsLogReport('js media set props before init', AVS_TAGS.MediaSDK_Operation);
    if (isSupportAV()) {
      this.socketInstance.setPropsBeforeInit({
        ...propsBeforeInit,
        enableWebtransport,
        webtransportPort,
      });
    } else {
      this.socketInstance.setPropsBeforeInit(propsBeforeInit);
    }
  };

  connectSocket(
    meetingState,
    socketStatus,
    videoState,
    isPreviewMode = false,
    useWBVideo = false,
  ) {
    this.useWBVideo = useWBVideo;
    if (this.isDestroyed) {
      return Promise.reject('the AVSocketService instance is destroyed!');
    }
    const init = (policy) => {
      if (this.isDestroyed) {
        return Promise.reject('the AVSocketService instance is destroyed!');
      }
      /** Already received 4098 from rwg, then notify media-sdk rwg join successfully */
      if (!this.isDestroyed && !isPreviewMode) {
        this.sendSocket(AVNotifyMediaSDKTypes.NOTIFY_SDK_JOIN_RWG_SUCCESS);
      }
      this.initParams(
        meetingState,
        socketStatus,
        videoState,
        policy,
        useWBVideo,
      );
      const canSharingDecode = isPreviewMode ? false : this.initSharingDecode();
      const canSharingEncode = isPreviewMode ? false : this.initSharingEncode();
      const canVideoDecode = this.initVideoDecode(isPreviewMode);
      const canVideoEncode = this.initVideoEncode(isPreviewMode);
      let canAudioDecode;
      let canAudioEncode;
      if (isAudioBridge()) {
        canAudioEncode = true;
        canAudioDecode = true;
        this.initAudioBridge(isPreviewMode);
      } else {
        canAudioDecode = this.initAudioDecode(isPreviewMode);
        canAudioEncode = this.initAudioEncode(isPreviewMode);
      }
      return {
        canSharingDecode,
        canSharingEncode,
        canVideoDecode,
        canVideoEncode,
        canAudioDecode,
        canAudioEncode,
      };
    };
    const tasks = [];
    const hasPolicy =
      isChromeOS() &&
      navigator &&
      navigator.managed &&
      navigator.managed.getManagedConfiguration;
    if (hasPolicy) {
      tasks.push(
        navigator.managed.getManagedConfiguration([
          'BandwidthLimitUp',
          'BandwidthLimitDown',
        ]),
      );
    }
    if (globalVariable.sdkDestroyPromise) {
      tasks.push(globalVariable.sdkDestroyPromise);
    }
    return Promise.all(tasks)
      .then(([result]) => {
        const policy = hasPolicy ? result : null;
        return init(policy);
      })
      .catch(() => {
        return init();
      });
  }

  notViewOnly = () => {
    const { isOriginhost } = this.meetingState;

    return (
      !this.isWebinar ||
      (this.isWebinar && !this.isViewOnly) ||
      (this.isWebinar && isOriginhost)
    );
  };

  initSharingDecode = () => {
    const {
      currentUser: { userId },
      confId,
      meetingNumber,
    } = this.meetingState;
    const { sharingRwcIpAddress } = this.avMeetingParams;

    //[3.5.0][MediaSDK_Operation] js media init sharing decode
    avsLogReport('js media init sharing decode', [
      AVS_TAGS.share_telemetry,
      AVS_TAGS.MediaSDK_Operation,
    ]);
    if (isSupportAV()) {
      performanceMark(
        // eslint-disable-next-line no-undef
        globalVariable.wcCTX().beenPreview
          ? PERFORMANCE_MARK.beenPreview_initSharingDecode
          : PERFORMANCE_MARK.inMeeting_initSharingDecode,
      );
      this.socketInstance.initSharingDecode(
        this.mediaWorkPath,
        sharingRwcIpAddress,
        userId,
        meetingConfig.debug,
        confId,
        meetingNumber,
        getMaxConcurrenyVideoThread(),
      );
    } else {
      this.socketInstance.initSharingDecode(sharingRwcIpAddress);
    }

    return true;
  };

  initSharingEncode = () => {
    const {
      currentUser: { userId },
      confId,
      meetingNumber,
    } = this.meetingState;
    const { sharingRwcIpAddress } = this.avMeetingParams;

    if (
      isSupportAV() &&
      this.notViewOnly() &&
      !isMobileDevice() &&
      (!isExternalControlledMode() || isGoogleMeetMode())
    ) {
      performanceMark(
        // eslint-disable-next-line no-undef
        globalVariable.wcCTX().beenPreview
          ? PERFORMANCE_MARK.beenPreview_initSharingEncode
          : PERFORMANCE_MARK.inMeeting_initSharingEncode,
      );
      //[3.5.0][MediaSDK_Operation] js media init sharing encode
      avsLogReport('js media init sharing encode', [
        AVS_TAGS.share_telemetry,
        AVS_TAGS.MediaSDK_Operation,
      ]);
      this.socketInstance.initSharingEncode(
        this.mediaWorkPath,
        sharingRwcIpAddress,
        userId,
        meetingConfig.debug,
        confId,
        meetingNumber,
        getMaxConcurrenyVideoThread(),
      );
      return true;
    }
    return false;
  };

  previewVideoDecode = () => {
    const { conID, webtransportPort, enableWebTransport } =
      easyStore.easyGet(MEMORYSTORAGE_KEYS.meetingInfo) || {};
    if (!conID) {
      return;
    }
    const commandSocketUrl = easyStore.easyGet(MEMORYSTORAGE_KEYS.rwgUrl) || '';
    const rwgHost = new URL(commandSocketUrl).host;
    const websocketUrl = getVideoRwcIpAddress({
      svcUrl: rwgHost,
      meetingNumber: meetingConfig.meetingNumber,
      conferenceID: conID,
    });

    //[3.5.0][MediaSDK_Operation] js media preview init
    avsLogReport('js media preview init', AVS_TAGS.MediaSDK_Operation);
    const payload = {
      videoDecode: {},
      videoDataChannel: {
        conId: conID,
      },
      videoDecodeMediaWss: {
        websocketUrl,
      },
    };
    if (enableWebTransport) {
      payload.videoDecodeWebtransport = {
        conId: conID,
        meetingNumber: meetingConfig.meetingNumber,
        rwgHost,
        webtransportPort,
      };
    }
    this.socketInstance.previewInit(payload);
  };

  previewVideoEncode = () => {
    const { conID, webtransportPort, enableWebTransport } =
      easyStore.easyGet(MEMORYSTORAGE_KEYS.meetingInfo) || {};
    if (!conID) {
      return;
    }
    const commandSocketUrl = easyStore.easyGet(MEMORYSTORAGE_KEYS.rwgUrl) || '';
    const rwgHost = new URL(commandSocketUrl).host;
    const websocketUrl = getVideoRwcIpAddress({
      svcUrl: rwgHost,
      meetingNumber: meetingConfig.meetingNumber,
      conferenceID: conID,
    });

    //[3.5.0][MediaSDK_Operation] js media preview init
    avsLogReport('js media preview init', AVS_TAGS.MediaSDK_Operation);
    const payload = {
      videoDataChannel: {
        conId: conID,
      },
      videoEncodeMediaWss: {
        websocketUrl,
      },
    };
    if (enableWebTransport) {
      payload.videoEncodeWebtransport = {
        conId: conID,
        meetingNumber: meetingConfig.meetingNumber,
        rwgHost,
        webtransportPort,
      };
    }
    this.socketInstance.previewInit(payload);
  };

  initAudioBridge = (isPreviewMode) => {
    const { conID, reportDomain, ABToken, supportLocalAB, rwgHost } =
      easyStore.easyGet(MEMORYSTORAGE_KEYS.meetingInfo) || {};
    if (reportDomain && ABToken && conID && ABToken) {
      //[3.5.0][MediaSDK_Operation] js media preview init
      avsLogReport(
        `js media [${isPreviewMode ? 'preview' : 'in-meeting'}] init`,
        [AVS_TAGS.MediaSDK_Operation, AVS_TAGS.audio_telemetry],
      );
      if (!isPreviewMode) {
        performanceMark(PERFORMANCE_MARK.inMeeting_initAudioBridge);
      }
      // although the API is called previewInit, but it's used for both preview and in-meeting
      this.socketInstance.previewInit({
        audioBridge: {
          nginxHost: reportDomain,
          rwgHost,
          cid: conID,
          abToken: ABToken,
          supportLocalAB,
        },
      });
    }
  };

  previewAudioDecode = () => {
    const { conID, webtransportPort, enableWebTransport } =
      easyStore.easyGet(MEMORYSTORAGE_KEYS.meetingInfo) || {};
    if (!conID) {
      return;
    }
    const audioUseWasm = isSupportAV() && isSupportAudioWorklet();

    const rwgHost = new URL(easyStore.easyGet(MEMORYSTORAGE_KEYS.rwgUrl) || '')
      .host;
    const websocketUrl = getAudioRwcIpAddress({
      svcUrl: rwgHost,
      meetingNumber: meetingConfig.meetingNumber,
      conferenceID: conID,
    });

    if (audioUseWasm) {
      //[3.5.0][MediaSDK_Operation] js media preview init
      const payload = {
        audioDecode: {},
        audioDataChannel: { conId: conID },
        audioDecodeMediaWss: {
          websocketUrl,
        },
      };
      if (enableWebTransport) {
        payload.audioDecodeWebtransport = {
          conId: conID,
          meetingNumber: meetingConfig.meetingNumber,
          rwgHost,
          webtransportPort,
        };
      }
      avsLogReport(
        `previewAudioDecode, payload: ${JSON.stringify(payload)}`,
        AVS_TAGS.MediaSDK_Operation,
      );
      this.socketInstance.previewInit(payload);
    }
  };

  previewAudioEncode = () => {
    const { conID, webtransportPort, enableWebTransport } =
      easyStore.easyGet(MEMORYSTORAGE_KEYS.meetingInfo) || {};
    if (!conID) {
      return;
    }
    const audioUseWasm = isSupportAV() && isSupportAudioWorklet();

    const rwgHost = new URL(easyStore.easyGet(MEMORYSTORAGE_KEYS.rwgUrl) || '')
      .host;
    const websocketUrl = getAudioRwcIpAddress({
      svcUrl: rwgHost,
      meetingNumber: meetingConfig.meetingNumber,
      conferenceID: conID,
    });

    if (audioUseWasm) {
      //[3.5.0][MediaSDK_Operation] js media preview init
      const payload = {
        audioDataChannel: { conId: conID },
        audioEncodeMediaWss: {
          websocketUrl,
        },
      };
      if (enableWebTransport) {
        payload.audioEncodeWebtransport = {
          conId: conID,
          meetingNumber: meetingConfig.meetingNumber,
          rwgHost,
          webtransportPort,
        };
      }
      avsLogReport(
        `previewAudioDecode, payload: ${JSON.stringify(payload)}`,
        AVS_TAGS.MediaSDK_Operation,
      );
      this.socketInstance.previewInit(payload);
    }
  };

  initVideoDecode = (isPreviewMode = false) => {
    const {
      currentUser: { userId },
      confId,
      meetingNumber,
      disableVideoQos,
    } = this.meetingState;
    const { videoRwcIpAddress } = this.avMeetingParams;
    const { isClientEnablehardwareAccelerationForReceivingVideo } =
      this.videoState;
    const enablehw =
      isSupportHardwareAcceleration() &&
      !!isClientEnablehardwareAccelerationForReceivingVideo;
    const isVideoQosOn = !disableVideoQos;

    if (isSupportAV()) {
      if (isPreviewMode) {
        this.previewVideoDecode();
        return false;
      } else {
        performanceMark(
          // eslint-disable-next-line no-undef
          globalVariable.wcCTX().beenPreview
            ? PERFORMANCE_MARK.beenPreview_initVideoDecode
            : PERFORMANCE_MARK.inMeeting_initVideoDecode,
        );
      }

      //[3.5.0][MediaSDK_Operation] js media init video decode
      avsLogReport('js media init video decode', [
        AVS_TAGS.MediaSDK_Operation,
        AVS_TAGS.video_telemetry,
      ]);
      this.socketInstance.initVideoDecode(
        this.mediaWorkPath,
        videoRwcIpAddress,
        userId,
        meetingConfig.debug,
        confId,
        meetingNumber,
        getMaxConcurrenyVideoThread(),
        enablehw,
        isVideoQosOn,
      );
      return true;
    }
    return false;
  };

  initVideoEncode = (isPreviewMode = false) => {
    const {
      currentUser: { userId },
      confId,
      meetingNumber,
      //VideoHd,
      disableVideoQos,
    } = this.meetingState;
    const { videoRwcIpAddress } = this.avMeetingParams;
    const { isClientEnablehardwareAccelerationForSendingVideo } =
      this.videoState;
    const enablehw =
      isSupportHardwareAcceleration() &&
      !!isClientEnablehardwareAccelerationForSendingVideo;
    const isVideoQosOn = !disableVideoQos;
    if (isSupportAV()) {
      if (isPreviewMode) {
        this.previewVideoEncode();
      } else {
        performanceMark(
          // eslint-disable-next-line no-undef
          globalVariable.wcCTX().beenPreview
            ? PERFORMANCE_MARK.beenPreview_initVideoEncode
            : PERFORMANCE_MARK.inMeeting_initVideoEncode,
        );
      }

      //[3.5.0][MediaSDK_Operation] js media init video encode
      avsLogReport('js media init video encode', [
        AVS_TAGS.MediaSDK_Operation,
        AVS_TAGS.video_telemetry,
      ]);
      this.socketInstance.initVideoEncode(
        this.mediaWorkPath,
        videoRwcIpAddress,
        userId,
        meetingConfig.debug,
        confId,
        meetingNumber,
        getMaxConcurrenyVideoThread(),
        isMSFTMode(),
        isVideoQosOn,
        true,
        enablehw,
        is360penablehw,
        isPreviewMode,
      );
      return true;
    }
    return false;
  };

  initAudioDecode = (isPreviewMode = false) => {
    const {
      currentUser: { userId },
      confId,
      meetingNumber,
      disableAudioQos,
    } = this.meetingState;
    const { audioRwcIpAddress } = this.avMeetingParams;
    const qosOn = !disableAudioQos;
    if (isPreviewMode) {
      this.previewAudioDecode();
      return false;
    } else {
      performanceMark(
        // eslint-disable-next-line no-undef
        globalVariable.wcCTX().beenPreview
          ? PERFORMANCE_MARK.beenPreview_initAudioDecode
          : PERFORMANCE_MARK.inMeeting_initAudioDecode,
      );
    }
    if (isSupportAV() && (this.isWebinar || isSupportAudioWorklet())) {
      avsLogReport('js media init audio decode', [
        AVS_TAGS.MediaSDK_Operation,
        AVS_TAGS.audio_telemetry,
      ]);
      this.socketInstance.initAudioDecode(
        this.mediaWorkPath,
        audioRwcIpAddress,
        userId,
        meetingConfig.debug,
        confId,
        meetingNumber,
        getMaxConcurrenyVideoThread(),
        qosOn,
      );
      return true;
    }
    return false;
  };

  initAudioEncode = (isPreviewMode = false) => {
    const {
      currentUser: { userId },
      confId,
      meetingNumber,
      disableAudioQos,
    } = this.meetingState;
    const { audioRwcIpAddress } = this.avMeetingParams;
    if (isPreviewMode) {
      this.previewAudioEncode();
    } else {
      performanceMark(
        // eslint-disable-next-line no-undef
        globalVariable.wcCTX().beenPreview
          ? PERFORMANCE_MARK.beenPreview_initAudioEncode
          : PERFORMANCE_MARK.inMeeting_initAudioEncode,
      );
    }
    if (
      isSupportAV() &&
      isSupportAudioWorklet() &&
      (this.notViewOnly() || this.isAllowToTalk)
    ) {
      const qosOn = !disableAudioQos;
      //[3.5.0][MediaSDK_Operation] js media init audio encode
      this.socketInstance.initAudioEncode(
        this.mediaWorkPath,
        audioRwcIpAddress,
        userId,
        meetingConfig.debug,
        confId,
        meetingNumber,
        getMaxConcurrenyVideoThread(),
        qosOn,
        isPreviewMode,
      );
      return true;
    }
    return false;
  };

  closeSocket() {
    infoLog('socket closing... ');
    avsLogReport('js media unInit', AVS_TAGS.MediaSDK_Operation);
    /* eslint-disable-next-line @babel/new-cap */
    this.socketInstance.JsMediaSDK_UnInit();
  }

  destroy() {
    warningLog('destroy SDK');
    avsLogReport('js media destroy', AVS_TAGS.MediaSDK_Operation);
    const p = this.socketInstance.destroy().finally(() => {
      if (globalVariable.sdkDestroyPromise === p) {
        globalVariable.sdkDestroyPromise = null;
      }
    });
    this.setPeerConnectionRecvReady(false);
    this.setPeerConnectionSendReady(false);
    this.isDestroyed = true;
    globalVariable.sdkDestroyPromise = p;
  }

  setPeerConnectionRecvReady(flag) {
    easyStore.easySet('peer_connection_recv_ready', flag, storeType.memory);
  }

  setPeerConnectionSendReady(flag) {
    easyStore.easySet('peer_connection_send_ready', flag, storeType.memory);
  }

  isPeerConnectionRecvReady() {
    return easyStore.easyGet('peer_connection_recv_ready') ?? false;
  }

  isPeerConnectionSendReady() {
    return easyStore.easyGet('peer_connection_send_ready') ?? false;
  }
  sendSocket(type, data) {
    /* eslint-disable-next-line @babel/new-cap */
    AVSocketOutboundMsg(data, type);
    if (
      type === AVNotifyMediaSDKTypes.ADD_RENDER_AUDIO ||
      type === AVNotifyMediaSDKTypes.JOIN_COMPUTER_AUDIO
    ) {
      this.joinVoipTimes += 1;
    }
    //[3.5.0][MediaSDK_Operation] js media notify mediasdk
    if (isExcludedMediaSendType(type)) {
      avsLogReport(
        `app to mediaSDK: [${
          AVNotifyMediaSDKTypeNames[type] ?? type
        }] \n${safeJsonStringifyWithNoNest(data)})}`,
        AVS_TAGS.MediaSDK_Operation,
      );
    }
    /* eslint-disable-next-line @babel/new-cap */
    return this.socketInstance.Notify_MeidaSDK(type, data);
  }

  getCanvasInfo() {
    return this.canvasInfo;
  }

  getMyCanvasInfo() {
    return this.myCanvasInfo;
  }

  setMyCanvasInfo(data) {
    this.myCanvasInfo = data;
  }

  getJoinVoIPTimes() {
    return this.joinVoipTimes;
  }

  enableAudioEncode({ audioRwcIpAddress, userId }) {
    const { confId, meetingNumber, disableAudioQos } = this.meetingState;
    if (isSupportAudioWorklet()) {
      const qosOn = !disableAudioQos;

      avsLogReport('js media init audio encode', [
        AVS_TAGS.MediaSDK_Operation,
        AVS_TAGS.audio_telemetry,
      ]);
      return this.socketInstance.initAudioEncode(
        this.mediaWorkPath,
        audioRwcIpAddress,
        userId,
        meetingConfig.debug,
        confId,
        meetingNumber,
        getMaxConcurrenyVideoThread(),
        qosOn,
      );
    }
    return Promise.reject(new Error('Not Support Audio Encode'));
  }
}
