import { laplace, trackingId, reportUrl, logConfig } from './laplace';
import md5 from '../crypto/md5';
import { LaplaceInterface } from '@zoom/laplace';
import { getRedux } from '../telemetry/telemetry-utils';
import { reportAction } from './log-service/avs-laplace-telemetry';
import { directReport } from './log-service/direct-report';
import { LogLevel } from '@zoom/laplace/build/interface';
import { globalVariable } from '../global-variable';

export const checkLaplace = () => {
  const isLaplaceExisted = Boolean(laplace.log);
  return isLaplaceExisted;
};

const mergeTags = (tag1, tag2) => {
  return [tag1, tag2]
    .flatMap((v) => v)
    .map((v) => {
      if (typeof v === 'function') {
        return v();
      }
      return v;
    })
    .filter((x) => Boolean(x));
};

/**
 * Laplace save log to local indexDB method.
 *
 * @param  {string} message - log content
 * @param  {string[]} aspectTags - global tags
 * @param  {string[]} tags - customize tags
 * @return {Promise} logWithEncryption Promise instance
 */
const log = async (message, aspectTags, tags) => {
  if (!(logConfig?.logLevel?.log ?? false) || !checkLaplace()) {
    return;
  }
  return laplace.log(message, mergeTags(aspectTags, tags));
};

const warn = async (message, aspectTags, tags) => {
  if (!(logConfig?.logLevel?.warn ?? false)) {
    return;
  }
  if (!checkLaplace()) {
    return;
  }
  return laplace.log(
    message,
    mergeTags(aspectTags, tags),
    LaplaceInterface.LogLevel.warn,
  );
};

const occurredError = [];
const error = async (message, aspectTags, tags) => {
  if (!logConfig?.logLevel?.error || !checkLaplace()) {
    return;
  }
  const state = getRedux()?.getState?.();
  let confData = {};
  if (state) {
    confData = {
      confId: state.meeting.confId,
      nodeId: state.meeting.currentUser.userId,
      conId: state.meeting.conId,
      svcUrl: state.meeting.svcUrl,
    };
  }
  const hashCode = md5(message);
  if (!occurredError[hashCode]) {
    occurredError[hashCode] = true;
    return laplace
      .log(message, mergeTags(aspectTags, tags), LogLevel.error, {
        messageHash: hashCode,
        ...confData,
      })
      .then(() => {
        reportToGlobalTracing({
          filter: (logItem) =>
            logItem.logLevel === LaplaceInterface.LogLevel.error,
        });
      });
  }
};

const print = (message) => {
  if (!(logConfig?.logLevel?.print ?? false)) {
    return;
  }
  // eslint-disable-next-line no-console
  console.log(message);
};

export const makeLogger = (tags) => {
  let aspectTags = [
    ...mergeTags([], tags),
    () => {
      return easyStore.easyGet('pwa-portal-trace-id'); // if pwa not exist, result is null,it will be filtered by laplace
    },
  ];
  return {
    log: (message, tags) => log(message, aspectTags, tags),
    error: (message, tags) => error(message, aspectTags, tags),
    warn: (message, tags) => warn(message, aspectTags, tags),
    print: (message) => print(message),
    directReport: (message, tags) => directReport(message, { tags }),
  };
};

export const reportToGlobalTracing = async (config, isManualReport) => {
  if (!checkLaplace() || !reportUrl) {
    return Promise.resolve();
  }
  if (isManualReport) {
    reportAction(true);
  }

  const { filter } = config || {};
  if (CLIENT_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn('[report filter]: ', filter);
  }
  return laplace.reportByFilter(filter);
};

export const reportPerformance = async () => {
  if (globalVariable.shouldReportPerformance) {
    return reportToGlobalTracing({ filter: 'performance_join_flow_point' });
  }
  return Promise.resolve();
};

export const reportWithNotificationToGlobalTracing = async (
  config,
  notification,
) => {
  if (!checkLaplace()) {
    return Promise.resolve();
  }

  const tags = ['REPORT_NOTIFICATION'];
  const pwaPortalTracingId = easyStore.easyGet('pwa-portal-trace-id');
  if (pwaPortalTracingId) {
    tags.push(pwaPortalTracingId);
  }
  globalVariable.avSocket?.socketInstance?.beforeReportToGlobalTracing?.();
  return laplace
    .log('', tags, LogLevel.log, {
      IM_NOTIFICATION: `${notification}\nTrackingId: ${trackingId}`,
    })
    .then(() => {
      const isManualReport = true;
      reportToGlobalTracing(config, isManualReport);
    });
};
