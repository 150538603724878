// success
export const INIT_SUCCESS_VIDEO = 1.1;
export const INIT_SUCCESS_VIDEO_ENCODE = 1;
export const INIT_SUCCESS_VIDEO_DECODE = 2;
export const INIT_SUCCESS_AUDIO = 1.2;
export const INIT_SUCCESS_AUDIO_ENCODE = 1;
export const INIT_SUCCESS_AUDIO_DECODE = 2;
export const INIT_SUCCESS_SHARING = 1.3;
export const INIT_SUCCESS_SHARING_ENCODE = 1;
export const INIT_SUCCESS_SHARING_DECODE = 2;
export const USER_GRANT_AUDIO_CAPTURE = 2;
export const CURRENT_VIDEO_RESOLUTION = 3;
export const JPEG_DEC_THREAD_OK = 4;
export const JPEG_DATA = 5;
export const SHARING_PARA = 6; // sharing size from sharing ws channel
export const SHARING_MORE_INFO = 6.1; // other data from sharing ws channel
export const VIDEO_DECODE_MAX_SIZE = 7;
export const CURRENT_CAPTURE_VIDEO_WIDTH_HEIGHT = 8;
export const START_VIDEO_CAPTURE_SUCCESS = 9;
export const STOP_VIDEO_CAPTURE_SUCCESS = 10;
export const START_REMOTE_CONTROL_SUCCESS = 11;
export const CANCEL_REMOTE_CONTROL_SUCCESS = 12;
export const REMOTE_CONTROL_COPIED_TEXT_NOTIFY = 13;
export const MONITOR_LOG = 14;
export const SHARING_DECODE_MAX_SIZE = 18;
export const REQUEST_AUDIO_BRIDGE_TOKEN = 19;
export const AES_GCM_IV_RESPONSE = 21;
export const SHARING_DESKTOP_STREAM_HAVE_AUDIO = 23;

export const JOIN_COMPUTER_AUDIO_COMPLETE = 24;
export const JOIN_DESKTOP_AUDIO_COMPLETE = 25;
export const LEAVE_COMPUTER_AUDIO_COMPLETE = 26;
export const LEAVE_DESKTOP_AUDIO_COMPLETE = 27;
export const AUDIO_SPEAKER_SET_SUCCESS = 33;
export const AUDIO_QOS_DATA = 'AUDIO_QOS_DATA';
export const VIDEO_QOS_DATA = 'VIDEO_QOS_DATA';
export const SHARE_QOS_DATA = 'VIDEOSHARE_QOS_DATA';
export const NETWORK_QUALITY_CHANGE = 'NETWORK_QUALITY_CHANGE';

export const AUDIO_LEVEL_INDICATOR = 38;

export const NETWORK_QUALITY_CHANGE_AUDIO = 'NETWORK_QUALITY_CHANGE_AUDIO';

export const AUDIO_PREVIEW_ASN = 49;

export const HID_STATUS_MUTE = 28;

export const START_VIDEO_STREAM_IN_MASK_SETTING_SUCCESS = 66;
export const START_VIDEO_STREAM_IN_VB_SETTING_SUCCESS = 94;

export const CURRENT_DECODE_VIDEO_QUALITY = 66.5;
export const CURRENT_DECODE_VIDEO_FPS = 66.6;

export const SHARING_FIRST_DECODE_FRAME_RECEIVED_SSRC = 70;

export const NEW_ACTIVE_SPEAKER_FIRST_FRAME_CALLBACK = 111;

// failed
export const INIT_FAILED = -1;
export const INIT_FAILED_VIDEO = -1.1;
export const INIT_FAILED_VIDEO_ENCODE = -1;
export const INIT_FAILED_VIDEO_DECODE = -2;
export const INIT_FAILED_AUDIO = -1.2;
export const INIT_FAILED_AUDIO_ENCODE = -1;
export const INIT_FAILED_AUDIO_DECODE = -2;
export const INIT_FAILED_SHARING = -1.3;
export const AUDIO_CAPTURE_FAILED = -2;
export const AUDIO_WEBSOCKET_BROKEN = -3;
export const VIDEO_WEBSOCKET_BROKEN = -4;
export const JPEG_DEC_THREAD_FAILED = -5;
export const AUDIO_ZERO_DATA = -6;
export const USER_FORBIDDED_CAPTURE_VIDEO = -8;
export const USER_CAMERA_IS_TAKEN_BY_OTHER_PROGRAMS = -9;
export const START_REMOTE_CONTROL_FAILED = -11;
export const AUDIO_SPEAKER_SET_ERROR = -21;
export const AUDIO_MIC_SET_ERROR = -41;
// export const DATA_CHANNEL_OFFER = 20;
export const SEND_MESSAGE_TO_RWG = 20;
export const AUDIO_AUTO_PLAY_FAILED = -24;
export const SHARING_DESKTOP_STREAM_HAVE_NO_AUDIO = -27;
export const WEBGL_LOST_IN_MULTI_VIEW = -32;
export const NOTIFY_UI_FAILOVER = -35;

export const DESKTOP_SHARING_ERROR = -20;
export const USER_STOP_DESKTOP_SHARING = -15;
// failover
export const FAIL_OVER_RESULT_TO_OUTSIDE_SDK = 21;

export const MASK_SETTING_PARA_ERROR = -33;
export const VB_SETTING_PARA_ERROR = -34;

export const VB_MODEL_PRELOADING_3S = 96;
export const VB_MODEL_PRELOADING_10S = 97;
export const VB_MODEL_PRELOADING_OK = 98;

// audioBridge
export const WCL_AUDIO_BRIDGE_RECONNECT = -29;
export const WCL_AUDIO_BRIDGE_RECONNECT_END = -31;
export const WCL_AUDIO_BRIDGE_FIRST_RECV_DATA = 114;
export const WCL_AUDIO_BRIDGE_CAN_SEND_DATA = 115;
export const FIRST_VIDEO_FRAME = 116;
export const PREVIEW_INIT_VIDEO_DECODE_SUCCESS = 117;
export const DEVICE_CHANGE_EVENT = 159;

export const SPEAKING_WHEN_MUTE = 121;

export const WHITEBOARD_SUCCESS = 30;
export const PREVIEW_INIT_AUDIO_DECODE_SUCCESS = 118;
export const SEND_REMOTE_CONTROL_QR_CODE = 55;
export const SELECT_RENDERER_TYPE_RESPONSE = 39;

export const AUDIO_STREAM_MUTED = 'AUDIO_STREAM_MUTED';
export const AUDIO_STREAM_UNMUTED = 'AUDIO_STREAM_UNMUTED';

export const NOTIFY_UI_WMSC_FAILOVER = -42;
export const NOTIFY_UI_WMSC_WSS_DISCONNECTED = -43;
export const WEBRTC_PC_RECONNECT = -45;
export const WEBRTC_PC_CONNECTED = 170;
export const UI_SUBSCRIBE_VIDEO = 132;
export const UI_UNSUBSCRIBE_VIDEO = 133;

export const START_ANNOTATION_FAILED = -130;
export const ANNO_UNDO_STATUS = 'ANNO_UNDO_STATUS';
export const ANNO_REDO_STATUS = 'ANNO_REDO_STATUS';

export const MEDIA_HEALTH_CHECK_FAILED = -129;
export const AUDIO_STREAM_FAILED = 31;
export const VIDEO_STREAM_FAILED = 32;
export const WEBRTC_SUBSCRIBE_FAILURE = -50;
export const WEBGL_CONTEXT_INVALID = -51;
export const WASM_MEMORY_FAIL = -52;
export const WORKLET_PROCESS_EXCEPTIONS = -53;
export const LOST_CAMERA_ACCESS = -60;
export const VB_VIDEOFRAME_COPYTO_ERROR = -70;

export const VIDEO_STREAM_MUTED = 'VIDEO_STREAM_MUTED';
export const VIDEO_STREAM_UNMUTED = 'VIDEO_STREAM_UNMUTED';

export const RECAPTURE_AUDIO = 160;

export const REQUEST_PERMISSION_RESULT = 162;
export const REQUEST_PERMISSION_POP_REMINDER = 163;
export const ANNO_VIEWER_REQUEST_DRAW = 'ANNO_VIEWER_REQUEST_DRAW';
export const AUDIO_TAG_ERROR = -138;
