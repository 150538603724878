import {
  isSupportPEPCFlow,
  setPreviewPermissionDismissStatus,
} from '../../global/service';

function handlePermissionChangeJob() {
  if (!isSupportPEPCFlow()) {
    return;
  }

  navigator.permissions
    .query({ name: 'microphone' })
    .then((permissionStatus) => {
      permissionStatus.onchange = function () {
        if (permissionStatus.state !== 'prompt') {
          setPreviewPermissionDismissStatus('audio', false);
        }
      };
    });

  navigator.permissions.query({ name: 'camera' }).then((permissionStatus) => {
    permissionStatus.onchange = function () {
      if (permissionStatus.state !== 'prompt') {
        setPreviewPermissionDismissStatus('video', false);
      }
    };
  });
  return;
}

export const taskHandlePermissionChange = [handlePermissionChangeJob];
